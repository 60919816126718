import { useMemo, useState } from 'react';
import {
  NEW_PRODUCT_INFO_ITEMS_DETAIL,
  NEW_PRODUCT_INFO_SUMMARY,
  PRODUCT_TYPE,
  PRODUCT_PERIOD_TYPE,
  MEMBERSHIP_ID,
  PRICE_STATUS,
  TRIAL_STATUS,
  AB_EXPERIMENTS,
} from '@/common/config';
import { useDA, useGASendEvent, useSundry } from '@/hooks';
import { useCommonStore, useUserStore } from '@/store';
import classNames from 'classnames';
import CustomIcon from '@/components/CustomIcon';
import { useDebounceFn } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { shouldUserUseNewModelName } from '@/common/gray';
import {
  getStripePortalUsingGET,
  getFastspringPortalUsingGET,
  addDiscountGet,
  billingDetailGET,
} from '@/services';
import Coupon from '@/layout/BasicLayout/components/BillingManageModal/coupon';

import type { IProduct, ResponseType } from '@/type';
import { ProductItem } from './index';
import DiscountBanner from './DiscountBanner';
import { hasCouponActivity } from '@/components/UpgradeButton';
import PayCard from './components/PayCard';
import PayChart from './components/PayChart';

import './newPay.less';
import { Modal, message } from 'antd';
import { BillingDetail } from '../BillingManageModal/interface';
import ToastContent from '@/components/ToastContent';

interface IPayProps {
  freeTrial?: number;
  products: IProduct[];
  priceStatus: PRICE_STATUS | undefined;
  trialStatus: TRIAL_STATUS;
  isLoading: boolean;
  shortMessage?: string;
  onBeforePay?: (params: { product: ProductItem; subType: string }) => void;
  handleUpgradeMultiply: (showUpgrade?: boolean) => void;
}

interface addDiscountProp {
  success?: boolean;
  message?: string;
}

const NewPay: React.FC<IPayProps> = (props) => {
  const {
    freeTrial,
    products,
    priceStatus,
    trialStatus,
    isLoading,
    shortMessage,
    onBeforePay,
    handleUpgradeMultiply,
  } = props;

  const { sendEvent } = useGASendEvent();
  const { sendDAEvent } = useDA();
  const { t } = useTranslation();

  const { discountInfo, userInfo } = useUserStore();
  const { billingDetail, setBillingDetail } = useCommonStore();
  const { experiment } = useSundry();
  const freeModelFeature = experiment(AB_EXPERIMENTS.STANDARD_FREE);

  const [isShowCouponModal, setIsShowCouponModal] = useState(false);
  const isGetCoupon = useMemo(() => {
    return billingDetail?.activity === 'NEWPOPAI';
  }, [billingDetail]);

  const [proType, setProType] = useState<PRODUCT_PERIOD_TYPE>(PRODUCT_PERIOD_TYPE.Year);
  const yearlyOrder = [
    MEMBERSHIP_ID.Free,
    MEMBERSHIP_ID.YearPro,
    MEMBERSHIP_ID.YearProPlus,
    MEMBERSHIP_ID.YearUnlimited,
  ];
  const monthlyOrder = [
    MEMBERSHIP_ID.Free,
    MEMBERSHIP_ID.MonthPro,
    MEMBERSHIP_ID.MonthProPlus,
    MEMBERSHIP_ID.MonthUnlimited,
  ];

  const isHaveProPlus = useMemo(() => {
    return products.some((item) => {
      return [MEMBERSHIP_ID.YearProPlus, MEMBERSHIP_ID.MonthProPlus].includes(
        item.membership.membershipId,
      );
    });
  }, [products]);

  const currentProducts = useMemo(() => {
    if (proType === PRODUCT_PERIOD_TYPE.Month) {
      return products
        .filter((item) =>
          [
            MEMBERSHIP_ID.Free,
            MEMBERSHIP_ID.MonthPro,
            MEMBERSHIP_ID.MonthProPlus,
            MEMBERSHIP_ID.MonthUnlimited,
          ].includes(item.membership.membershipId),
        )
        .sort((a, b) => {
          return (
            monthlyOrder.indexOf(a.membership.membershipId) -
            monthlyOrder.indexOf(b.membership.membershipId)
          );
        });
    }
    return products
      .filter((item) =>
        [
          MEMBERSHIP_ID.Free,
          MEMBERSHIP_ID.YearPro,
          MEMBERSHIP_ID.YearProPlus,
          MEMBERSHIP_ID.YearUnlimited,
        ].includes(item.membership.membershipId),
      )
      .sort((a, b) => {
        return (
          yearlyOrder.indexOf(a.membership.membershipId) -
          yearlyOrder.indexOf(b.membership.membershipId)
        );
      });
  }, [proType, products]);

  const formatPrice = (item: IProduct) => {
    const productPriceNum = Number(item.price) || 0;
    let formatPrice;
    if (
      [MEMBERSHIP_ID.YearPro, MEMBERSHIP_ID.YearProPlus, MEMBERSHIP_ID.YearUnlimited].includes(
        item?.membership?.membershipId,
      )
    ) {
      formatPrice = (productPriceNum / 12 / 100).toFixed(1);
    } else {
      formatPrice = productPriceNum / 100;
    }
    return Number(formatPrice);
  };

  const formatstrikePrice = (price: string | number | undefined) => {
    const productPriceNum = Number(price) || 0;
    return Number(productPriceNum / 100);
  };

  const formatProducts = useMemo(() => {
    const result: ProductItem[] = [];
    const showProductType = isHaveProPlus
      ? [PRODUCT_TYPE.Free, PRODUCT_TYPE.Pro, PRODUCT_TYPE.ProPlus, PRODUCT_TYPE.Unlimited]
      : [PRODUCT_TYPE.Free, PRODUCT_TYPE.Pro, PRODUCT_TYPE.Unlimited];

    currentProducts.map((item: IProduct, index: number) => {
      const productType = showProductType[index];
      const price = Number(item.price) || 0;
      result.push({
        productType,
        productIds: item.productIds,
        productPriceNum: formatPrice(item),
        price: (price / 100).toFixed(1),
        productPriceText: item.currency,
        productInfoSummary: NEW_PRODUCT_INFO_SUMMARY[productType],
        // @ts-ignore
        productInfoItemsDetail: NEW_PRODUCT_INFO_ITEMS_DETAIL(
          {
            type: productType,
            gpt3Quota: item.membership.gpt3Quota,
            gpt4Quota: item.membership.gpt4Quota,
            pdfPageQuota: item.membership.pdfPageQuota,
            uploadQuota: item.membership.uploadQuota,
          },
          shouldUserUseNewModelName(userInfo),
          freeModelFeature || false,
        ),
        membershipId: item.membership.membershipId,
        strikePrice: formatstrikePrice(item.strikePrice),
      });
    });
    return result;
  }, [currentProducts, isHaveProPlus]);

  const payInfos = useMemo(
    () =>
      isLoading
        ? [
            {
              productType: PRODUCT_TYPE.Pro,
            },
            {
              productType: PRODUCT_TYPE.Unlimited,
            },
          ]
        : formatProducts.filter((produce) => produce.productType !== PRODUCT_TYPE.Free),
    [formatProducts],
  );
  const { memberTypes } = useMemo(() => {
    const monthlyTypes = [
      isHaveProPlus ? MEMBERSHIP_ID.MonthProPlus : MEMBERSHIP_ID.MonthPro,
      MEMBERSHIP_ID.MonthUnlimited,
    ];
    const yearlyTypes = [
      isHaveProPlus ? MEMBERSHIP_ID.YearProPlus : MEMBERSHIP_ID.YearPro,
      MEMBERSHIP_ID.YearUnlimited,
    ];
    return {
      memberTypes: [
        {
          memberType: MEMBERSHIP_ID.Free,
          name: 'FREE',
          data: products.find((p) => p.membership?.membershipId === MEMBERSHIP_ID.Free),
        },
      ].concat(
        (proType === PRODUCT_PERIOD_TYPE.Month ? monthlyTypes : yearlyTypes).map((memberType) => ({
          memberType,
          name: [MEMBERSHIP_ID.MonthUnlimited, MEMBERSHIP_ID.YearUnlimited].includes(memberType)
            ? 'Unlimited'
            : 'PRO',
          data: products.find((p) => p.membership?.membershipId === memberType),
        })),
      ),
    };
  }, [proType, products]);

  const handlePay = async (product: ProductItem) => {
    sendDAEvent('ProductPurchaseModalButtonClick', {
      couponid: discountInfo?.desc || '',
      currency: product.productPriceText,
      desc: `${
        proType === PRODUCT_PERIOD_TYPE.Year ? 'annually' : 'monthly'
      }_${product.productType.toLocaleLowerCase()}`,
      discount_rate:
        (proType === PRODUCT_PERIOD_TYPE.Year
          ? discountInfo?.yearlyDiscount
          : discountInfo?.monthlyDiscount) || 0,
      price: Number(product.price),
      button_name:
        proType === PRODUCT_PERIOD_TYPE.Year
          ? product.productType === PRODUCT_TYPE.Unlimited
            ? 'subscribe_annually_unlimited'
            : 'subscribe_annually_pro'
          : product.productType === PRODUCT_TYPE.Unlimited
          ? 'subscribe_monthly_unlimited'
          : 'subscribe_monthly_pro',
    });
    onBeforePay?.({
      product,
      subType: proType,
    });
  };

  const { run: handlePayDebounce } = useDebounceFn(handlePay, {
    wait: 2000,
    leading: true,
    trailing: false,
  });

  const handleProTypeChange = (proType: PRODUCT_PERIOD_TYPE) => {
    setProType(proType);
    sendDAEvent('ProductPurchaseModalButtonClick', {
      button_name: proType === PRODUCT_PERIOD_TYPE.Month ? 'monthly' : 'annually',
    });
    sendEvent('ClickPayTab', {
      tabType: proType,
    });
  };
  const handleBilling = (showUpgrade?: boolean) => {
    sendEvent('Managerbilling');
    if (freeTrial) {
      sendEvent('Freetrialmanagerbilling');
    }
    handleUpgradeMultiply(showUpgrade);
  };

  const getBillingDetail = async () => {
    try {
      const resData = await billingDetailGET<ResponseType<BillingDetail>>();
      setBillingDetail(resData.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleBillingDetails = async () => {
    let res: ResponseType<string> | undefined;
    try {
      if (userInfo.stripeCustomer) {
        res = await getStripePortalUsingGET<ResponseType<string>>({
          customer: userInfo.stripeCustomer as string,
          callbackUrl: window.location.href,
        });
      } else if (userInfo.fastspringCustomer) {
        res = await getFastspringPortalUsingGET<ResponseType<string>>({
          accountId: userInfo.fastspringCustomer as string,
        });
      }
      res?.data && window.open(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCoupon = () => {
    if (!isGetCoupon) {
      handleAddDiscount();
    } else {
      message.open({
        content: <ToastContent icon="success" content={t('common.receivedCoupon')} />,
      });
    }
  };

  const { run: handleCouponDebounce } = useDebounceFn(handleCoupon, {
    wait: 5000,
    leading: true,
    trailing: false,
  });

  const handleAddDiscount = async () => {
    try {
      const res = await addDiscountGet<ResponseType<addDiscountProp>>({
        coupon: discountInfo?.discountCouponCode as string,
      });
      if (res.data?.success) {
        setIsShowCouponModal(true);
        getBillingDetail(); // 此处三方可能有延迟，不能及时获取结果，所以关闭coupon弹窗时，再次调用
      } else {
        message.error(res.data?.message);
      }
    } catch (e: any) {
      const reqMsg = e?.message;
      const serverMsg = e?.response?.data?.message;
      const dataMsg = e?.data?.message;
      const msg = dataMsg || serverMsg || reqMsg || null;
      msg && message.error(msg);
    }
  };

  const extraFooterOperateRender = () => (
    <div
      className="coupon-get"
      onClick={() => {
        setIsShowCouponModal(false);
        getBillingDetail();
      }}
    >
      {t('common.gotIt')}
    </div>
  );

  // 是否有活动折扣
  const hasActiveDiscount = useMemo(() => {
    return hasCouponActivity(discountInfo);
  }, [discountInfo]);

  // 折扣，如果有活动折扣，显示活动折扣，否则显示默认折扣
  const discount = useMemo(() => {
    return discountInfo?.yearlyDiscount && discountInfo?.yearlyDiscount > 0
      ? discountInfo?.yearlyDiscount * 100
      : 16;
  }, [discountInfo?.yearlyDiscount]);

  // 是否显示折扣标签，如果有活动折扣，且选择了月，在年上显示活动折扣标签；如果没有活动折扣，显示默认折扣标签
  const showDiscountLabel = useMemo(() => {
    if (hasActiveDiscount) {
      return proType === PRODUCT_PERIOD_TYPE.Month;
    }
    return true;
  }, [hasActiveDiscount, proType]);

  return (
    <div className="product-wrapper upgrade">
      <div className="btn-group">
        <button
          className={classNames({
            active: proType === PRODUCT_PERIOD_TYPE.Month,
          })}
          onClick={() => handleProTypeChange(PRODUCT_PERIOD_TYPE.Month)}
        >
          {t('layout.pay.monthly')}
        </button>
        <div
          className={classNames(
            'btn-split-container',
            proType !== PRODUCT_PERIOD_TYPE.Year && 'reverse',
          )}
        >
          <CustomIcon type="payCardNaviSplit" />
        </div>
        <button
          className={classNames({
            active: proType === PRODUCT_PERIOD_TYPE.Year,
          })}
          onClick={() => handleProTypeChange(PRODUCT_PERIOD_TYPE.Year)}
        >
          {t('layout.pay.annually')}
          {showDiscountLabel && (
            <div className="discount">
              <CustomIcon type="discountArrow" className="discount-arrow" />
              {t('layout.pay.save16', { discount })}
            </div>
          )}
        </button>
      </div>
      {shortMessage && (
        <div className="pay-desc upgrade">
          <span className="pay-desc-reason">{shortMessage}</span> {t('common.please')}
        </div>
      )}
      <DiscountBanner />
      <div className="pay-card-list">
        {payInfos.map((payInfo) => (
          <PayCard
            product={payInfo as ProductItem}
            proType={proType}
            priceStatus={priceStatus}
            trialStatus={trialStatus}
            onPay={() => {
              if (userInfo.membershipId === MEMBERSHIP_ID.Free) {
                handlePayDebounce(payInfo as ProductItem);
              } else {
                handleBilling(true);
              }
            }}
            isLoading={isLoading}
            isGetCoupon={isGetCoupon}
            handleCoupon={isGetCoupon ? handleCoupon : handleCouponDebounce}
          />
        ))}
      </div>
      {userInfo.stripeCustomer || userInfo.fastspringCustomer ? (
        <div
          className="billing-entry"
          onClick={() => {
            handleBillingDetails();
          }}
        >
          <CustomIcon type="popai-a-tishi1" />
          <p>{t('layout.pay.orderDetails')}</p>
          <CustomIcon className="arrow-icon" type="arrowRightSquare" />
        </div>
      ) : null}
      <p className="feature-title">{t('layout.pay.complateTitle')}</p>
      <PayChart memberTypes={memberTypes} isLoading={isLoading} />
      {isShowCouponModal && (
        <Modal
          open={true}
          width="356px"
          footer={null}
          centered
          onCancel={() => {
            setIsShowCouponModal(false);
            getBillingDetail();
          }}
          wrapClassName="coupon-container"
        >
          <Coupon type="StudentSummer" extraFooterOperateRender={extraFooterOperateRender} />
        </Modal>
      )}
    </div>
  );
};

export default NewPay;
